import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  EmailAuthProvider,
  linkWithCredential,
  getAuth,
} from 'firebase/auth';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';

import createRefHandler from '@/core/utils/createRefHandler';
import { auth } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';

/**
 * Represents the structure of the form data for signing-in a user.
 *
 * @typedef {Object} FormData
 * @property {string} email - The user's email.
 */
type FormData = {
  email: string;
};

/**
 * Component for handling sign-in functionality.
 * Allows users to sign-in with their email and password, or with Google.
 * @returns {React.FC} ForgotPasswordScreen component.
 */
const EmailLinkSignInScreen: React.FC = () => {
  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // useFormValidations hook initialization
  const { emailValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '' },
  });

  // Registering input fields with validation rules
  const emailRef = register('email', emailValidation()).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      // Construct the email link credential from the current URL.
      await signInWithEmailLink(auth, data.email, window.location.href);
      const credential = EmailAuthProvider.credentialWithLink(data.email, window.location.href);
      await linkWithCredential(getAuth().currentUser!, credential);
      console.log('Successfully signed in with email link!');
    } catch (error: any) {
      handleEmailSignInError(error, { ...data });
    } finally {
      setLoading(false);
    }
  };

  // Handle error messages for email sign-in
  const handleEmailSignInError = (error: any, data: FormData) => {
    const errorMappings: any = {
      'auth/user-not-found': { field: 'email', message: 'El usuario no existe' },
      'auth/invalid-email': { field: 'email', message: 'El correo no corresponde al correo usado para registrarse' },
      'auth/user-disabled': { field: 'email', message: 'El usuario ha sido deshabilitado' },
    };

    const defaultError = { field: 'email', message: 'Ha ocurrido un error' };
    const { field, message } = errorMappings[error.code] || defaultError;

    console.error(error.code, field, message);
    reset({ ...data });
    setError(field, { type: 'custom', message });
  };

  // Function to navigate back to the sign-in screen
  const navigateToSignIn = useCallback(() => {
    navigate('/auth/sign-in');
  }, [navigate]);

  useEffect(() => {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
      navigateToSignIn();
    }
  }, [navigateToSignIn]);

  return (
    <MDBContainer className="mt-5">
      <section className="text-center">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="5">
            <MDBCard>
              <MDBCardBody className="p-4">
                <h2 className="fw-bold mb-2 text-uppercase">Iniciar sesión</h2>
                <p className="mb-5">Ingresa tu correo electrónico para continuar</p>

                <MDBValidation onSubmit={handleSubmit(onSubmit)}>
                  {/* Email Input Field */}
                  <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-4">
                    <MDBInput
                      label="Correo electrónico"
                      {...register('email', emailValidation())}
                      type="email"
                      ref={createRefHandler(emailRef, errors.email)}
                    />
                  </MDBValidationItem>

                  {/* Submit Button with Loading Indicator */}
                  <MDBBtn
                    type="submit"
                    block
                    size="lg"
                    disabled={loading || !isDirty}
                    className={!!errors.email ? 'mt-3' : ''}
                  >
                    {loading ? (
                      <>
                        <MDBSpinner size="sm" role="status" className="me-2" />
                        Ingresando...
                      </>
                    ) : (
                      <>Continuar</>
                    )}
                  </MDBBtn>

                  {/* Forgot Password Button */}
                  <MDBBtn
                    type="button"
                    color="link"
                    rippleColor="light"
                    block
                    size="lg"
                    className="text-transform-none fs-6"
                    onClick={navigateToSignIn}
                  >
                    Regresar
                  </MDBBtn>
                </MDBValidation>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default React.memo(EmailLinkSignInScreen);
