import React from 'react';
import Container from '@/features/common/components/Container';

/**
 * DashboardScreen component represents the main dashboard view in the application.
 * @returns The DashboardScreen component.
 */
const DashboardScreen: React.FC = () => {
  return (
    <Container title="Inicio">
      <h2 className="h1 fw-bold mb-4 d-flex align-items-center">Dashboard</h2>
    </Container>
  );
};

export default React.memo(DashboardScreen);

