import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';

import createRefHandler from '@/core/utils/createRefHandler';
import { auth, googleProvider } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';

/**
 * Represents the structure of the form data for signing-in a user.
 *
 * @typedef {Object} FormData
 * @property {string} email - The user's email.
 * @property {string} password - The user's password.
 */
type FormData = {
  email: string;
  password: string;
};

/**
 * Component for handling sign-in functionality.
 * Allows users to sign-in with their email and password, or with Google.
 * @returns {React.FC} ForgotPasswordScreen component.
 */
const SignInScreen: React.FC = () => {
  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // useFormValidations hook initialization
  const { emailValidation, passwordValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);
  const [googleAuthenticationError, setGoogleAuthenticationError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', password: '' },
  });

  // Registering input fields with validation rules
  const emailRef = register('email', emailValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
    } catch (error: any) {
      handleEmailSignInError(error, { ...data });
    } finally {
      setLoading(false);
    }
  };

  // Handle Google sign-in authentication
  const handleGoogleAuthentication = async () => {
    setLoadingGoogle(true);
    try {
      await signInWithPopup(auth, googleProvider);
      setGoogleAuthenticationError(null);
    } catch (error: any) {
      handleGoogleSignInError(error);
    } finally {
      setLoadingGoogle(false);
    }
  };

  // Handle error messages for email sign-in
  const handleEmailSignInError = (error: any, data: FormData) => {
    const errorMappings: any = {
      'auth/user-not-found': { field: 'email', message: 'El usuario no existe' },
      'auth/invalid-email': { field: 'email', message: 'El correo electrónico no es válido' },
      'auth/user-disabled': { field: 'email', message: 'El usuario ha sido deshabilitado' },
      'auth/invalid-password': { field: 'password', message: 'Ingresa una contraseña válida' },
      'auth/invalid-credential': { field: 'password', message: 'Contraseña incorrecta' },
      'auth/internal-error': { field: 'password', message: 'Ha ocurrido un error interno' },
    };

    const defaultError = { field: 'password', message: 'Ha ocurrido un error' };
    const { field, message } = errorMappings[error.code] || defaultError;

    console.error(error);
    reset({ ...data });
    setError(field, { type: 'custom', message });
  };

  // Handle error messages for Google sign-in
  const handleGoogleSignInError = (error: any) => {
    const errorMappings: any = {
      'auth/popup-closed-by-user': 'Se ha cancelado el inicio de sesión',
      'auth/popup-blocked': 'Se ha bloqueado el inicio de sesión por el navegador',
      'auth/cancelled-popup-request': 'Se ha cancelado el inicio de sesión',
      'auth/user-disabled': 'El usuario ha sido deshabilitado',
      'auth/user-not-found': 'El usuario no existe',
    };
    const defaultError = 'Ha ocurrido un error';
    const errorMessage = errorMappings[error.code] || defaultError;

    console.error(error.code);
    setGoogleAuthenticationError(errorMessage);
  };

  // Function to navigate back to the sign-in screen
  const navigateToForgotPassword = () => {
    navigate('/auth/forgot-password');
  };

  return (
    <MDBContainer className="mt-5">
      <section className="text-center">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="5">
            <MDBCard>
              <MDBCardBody className="p-4">
                <h2 className="fw-bold mb-2 text-uppercase">Iniciar sesión</h2>
                <p className="mb-5">Ingresa tu usuario y contraseña</p>

                <MDBValidation onSubmit={handleSubmit(onSubmit)}>
                  {/* Email Input Field */}
                  <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-4">
                    <MDBInput
                      label="Correo electrónico"
                      {...register('email', emailValidation())}
                      type="email"
                      ref={createRefHandler(emailRef, errors.email)}
                    />
                  </MDBValidationItem>

                  {/* Password Input Field */}
                  <MDBValidationItem
                    className={!!errors.email ? 'pt-4' : ''}
                    feedback={errors.password?.message || ' '}
                    invalid={!!errors.password}
                  >
                    <MDBInput
                      label="Contraseña"
                      {...register('password', passwordValidation())}
                      type="password"
                      ref={createRefHandler(passwordRef, errors.password)}
                    />
                  </MDBValidationItem>

                  {/* Forgot Password Button */}
                  <MDBBtn
                    type="button"
                    color="link"
                    rippleColor="light"
                    block
                    size="lg"
                    className="my-3 text-transform-none fs-6"
                    onClick={navigateToForgotPassword}
                  >
                    Olvidaste tu contraseña?
                  </MDBBtn>

                  {/* Submit Button with Loading Indicator */}
                  <MDBBtn type="submit" block size="lg" disabled={loading || !isDirty} className="mt-0 mb-3">
                    {loading ? (
                      <>
                        <MDBSpinner size="sm" role="status" className="me-2" />
                        Ingresando...
                      </>
                    ) : (
                      <>Ingresar</>
                    )}
                  </MDBBtn>

                  <div className="text-center">
                    <p>o ingresa con:</p>

                    {/* Google authentication button */}
                    <MDBBtn
                      color="primary"
                      block
                      type="button"
                      size="lg"
                      onClick={() => handleGoogleAuthentication()}
                      style={{ background: '#EA4335' }}
                      disabled={loadingGoogle}
                    >
                      {loadingGoogle && (
                        <>
                          <MDBSpinner size="sm" role="status" className="me-2" />
                          Ingresando...
                        </>
                      )}
                      {!loadingGoogle && (
                        <>
                          <MDBIcon fab icon="google" className="me-2" />
                          Google
                        </>
                      )}
                    </MDBBtn>

                    {/* Google authentication error message */}
                    {googleAuthenticationError !== null && (
                      <p className="invalid-feedback d-block position-relative mb-0 mt-3">
                        {googleAuthenticationError}
                      </p>
                    )}
                  </div>
                </MDBValidation>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default SignInScreen;
