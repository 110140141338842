import React from 'react';
import Container from '@/features/common/components/Container';

/**
 * CandidatesScreen component represents the list of candidates in the application.
 * @returns The CandidatesScreen component.
 */
const CandidatesScreen: React.FC = () => {
  return (
    <Container title="Candidatos">
      <h2 className="h1 fw-bold mb-4 d-flex align-items-center">Candidatos</h2>
    </Container>
  );
};

export default React.memo(CandidatesScreen);

