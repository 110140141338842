import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import {
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBSideNav,
  MDBSideNavLink,
  MDBSideNavItem,
  MDBScrollbar,
} from 'mdb-react-ui-kit';

import { auth } from '@/core/firebase';
import { useUser } from '@/core/hooks/useUser';
import InitialsAvatar from '@/features/common/components/Avatar';
import Logo from '@/assets/logo.png';

/**
 * Props for the Header component.
 */
export interface HeaderProps {
  title: string;
  actions?: React.ReactNode;
}

/**
 * Header component that provides the main navigation and user options in the app.
 * It includes a side navigation and a top navigation bar.
 *
 * @param {HeaderProps} props - The props for the component.
 * @returns The Header component.
 *
 */
const Header: React.FC<HeaderProps> = ({ title, actions }) => {
  // Navigation hooks
  const navigate = useNavigate();

  // User hook
  const { user, setUser } = useUser();

  // State for the side navigation
  const [basicOpen, setBasicOpen] = useState(true);
  const [mode, setMode] = useState('side');
  const [backDrop, setBackdrop] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  // Refs for the side navigation header and footer
  const navHeaderRef = useRef(null);
  const navFooterRef = useRef(null);

  /**
   * Adjusts navigation mode and visibility based on screen width.
   */
  const handleResize = useCallback(() => {
    const isLargeScreen = window.matchMedia('screen and (min-width: 1400px)').matches;
    setMode(isLargeScreen ? 'push' : 'side');
    setBackdrop(!isLargeScreen);
    setBasicOpen(isLargeScreen);
  }, []);

  /**
   * Effect to handle navigation mode and visibility based on screen width.
   */
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  /**
   * Signs out the current user from the application.
   */
  async function handleSignOut() {
    await signOut(auth);
    setUser(null);
  }

  /**
   * Navigates to a specified route.
   * @param {string} path - The route to navigate to.
   */
  const navigateTo = (path: string) => () => navigate(path);

  /**
   * Adjusts the height of the side navigation content area.
   */
  useEffect(() => {
    setContentHeight(
      window.innerHeight -
        (((navHeaderRef.current as HTMLElement | null)?.clientHeight ?? 0) +
          ((navFooterRef.current as HTMLElement | null)?.clientHeight ?? 0))
    );
  }, [navHeaderRef, navFooterRef]);

  return (
    <>
      <MDBSideNav backdrop={backDrop} mode={mode} open={basicOpen} absolute getOpenState={setBasicOpen}>
        <div ref={navHeaderRef} className="text-center flex-shrink-1">
          <h4 className="py-3 h4 m-0 fw-bold">
            <img src={Logo} alt="Logo" />
          </h4>
        </div>
        <MDBScrollbar sidenav tag="ul" suppressScrollX style={{ height: contentHeight }}>
          <MDBSideNavItem>
            <MDBSideNavLink onClick={navigateTo('/dashboard')}>
              <MDBIcon fas icon="home" className="me-3" />
              Inicio
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink onClick={navigateTo('/forms')}>
              <MDBIcon fas icon="table" className="me-3" />
              Formularios
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink onClick={navigateTo('/templates')}>
              <MDBIcon fab icon="wpforms" className="me-3" />
              Plantillas
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <MDBSideNavLink onClick={navigateTo('/candidates')}>
              <MDBIcon fas icon="users" className="me-3" />
              Candidatos
            </MDBSideNavLink>
          </MDBSideNavItem>
          {user?.accessLevel === 'super-admin' && (
            <MDBSideNavItem>
              <MDBSideNavLink onClick={navigateTo('/admins')}>
                <MDBIcon fas icon="users-cog" className="me-3" />
                Administradores
              </MDBSideNavLink>
            </MDBSideNavItem>
          )}
        </MDBScrollbar>
        <div ref={navFooterRef} className="text-center mt-auto" style={{ minHeight: '3rem' }}>
          <hr className="m-0" />
          <MDBDropdown dropup>
            <MDBDropdownToggle tag="a" className="hidden-arrow nav-link text-start p-3 cursor-pointer">
              <InitialsAvatar name={user?.name} style={{ width: '18px', height: '18px', fontSize: 10 }} />{' '}
              <span className="ms-1">Cuenta</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu style={{ width: 240 }} className="shadow-4-strong rounded-0">
              <MDBDropdownItem tag="span" className="dropdown-item-text">
                <p className="m-0 fw-bold text-dark">{user?.name}</p>
                <p className="m-0 text-body">{user?.email}</p>
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem link disabled aria-disabled={true}>
                {user?.accessLevel
                  .split('-')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </MDBDropdownItem>
              <MDBDropdownItem divider />
              <MDBDropdownItem link childTag="button" onClick={navigateTo('/profile')}>
                Mi perfil
              </MDBDropdownItem>
              <MDBDropdownItem link childTag="button" onClick={() => handleSignOut()}>
                Cerrar Sesión
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      </MDBSideNav>

      <MDBNavbar fixed="top" expand="xs" light bgColor="light" id="main-navbar">
        <MDBContainer fluid className="px-4">
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setBasicOpen(!basicOpen)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBNavbarBrand>
            <h1 className="h4 m-0 fw-bold d-flex align-items-center">{title}</h1>
          </MDBNavbarBrand>

          <MDBNavbarNav right fullWidth={false}>
            <MDBNavbarItem>{actions}</MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
    </>
  );
};

export default React.memo(Header);
