import React from 'react';
import { FieldError } from 'react-hook-form';

/**
 * Creates a ref handler function that sets custom validity based on form errors.
 * 
 * @param {React.RefCallback<HTMLInputElement>} ref - The ref callback from react-hook-form.
 * @param {FieldError | undefined} error - The error object for a specific form field.
 * @returns {React.RefCallback<HTMLInputElement>} - A ref callback function that sets custom validity.
 */
const createRefHandler = (
  ref: React.RefCallback<HTMLInputElement>,
  error: FieldError | undefined
): React.RefCallback<HTMLInputElement> => (element: HTMLInputElement | null) => {
  if (element) {
    // Set the ref from react-hook-form
    ref(element);
    
    // Set custom validity based on error
    element.setCustomValidity(error?.message ?? "");

    // Add active class if the input has a value and doesn't have the active class already
    // Remove active class if the input is empty and has the active class
    if(element.value !== "" && !element.classList.contains("active")) element.classList.add("active");
    else if(element.value === "" && element.classList.contains("active")) element.classList.remove("active");
  }
};

export default createRefHandler;