import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { httpsCallable } from 'firebase/functions';
import { MDBBtn, MDBIcon, MDBInput, MDBSpinner, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';

import { auth, functions } from '@/core/firebase';
import { useUser } from '@/core/hooks/useUser';
import createRefHandler from '@/core/utils/createRefHandler';
import useFormValidations from '@/core/hooks/useFormValidations';
import { UserModel } from '@/features/auth/data/models/UserModel';
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from 'firebase/auth';

/**
 * Represents the structure of the user's profile details for form submission.
 *
 * @typedef {Object} FormData
 * @property {string} name - The user's name.
 * @property {string} email - The user's email address.
 * @property {string} phone - The user's phone number.
 * @property {string} password - The user's password.
 */
type FormData = {
  name: string;
  email: string;
  phone: string;
  password?: string;
};

/**
 * Component for editting user profile, allowing users to edit their name, email and phone.
 * @returns {React.FC} The ProfileEditScreen component.
 */
const UserDetailsForm: React.FC = () => {
  // User hook for state management
  const { user, setUser } = useUser();

  // Form validation rules hook
  const { nameValidation, emailValidation, phoneValidation, passwordValidation } = useFormValidations();

  // Setting up state management
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    setError,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { name: user?.name || '', email: user?.email || '', phone: user?.phone || '', password: '' },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', nameValidation()).ref;
  const emailRef = register('email', emailValidation()).ref;
  const phoneRef = register('phone', phoneValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;

  // Update form fields when user data changes
  useEffect(() => {
    setValue('name', user?.name || '');
    setValue('email', user?.email || '');
    setValue('phone', user?.phone || '');
    setValue('password', '');
  }, [user, setValue]);

  // Firebase function for updating user information
  const editUserInfo = httpsCallable(functions, 'editUserInfo');

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    // Reauthenticate user with current password
    try {
      await reauthenticateWithCredential(auth.currentUser!, EmailAuthProvider.credential(user?.email!, data.password!));
    } catch (error: any) {
      console.error('Error during user password validation:', error);

      switch (error.code) {
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
          setError('password', { message: 'Contraseña incorrecta.' });
          break;
        default:
          setGeneralError('Error al cambiar la contraseña.');
          break;
      }

      setLoading(false);
      return;
    }

    // If email is changed, update user email
    if (data.email === user?.email) {
      try {
        await updateEmail(auth.currentUser!, data.email);
      } catch (error: any) {
        console.error('Error during user information update:', error);

        switch (error.code) {
          case 'auth/email-already-in-use':
            setError('email', { message: 'El correo electrónico ya está en uso.' });
            break;
          case 'auth/invalid-email':
            setError('email', { message: 'El correo electrónico no es válido.' });
            break;
          default:
            setGeneralError('Error al cambiar el correo electrónico.');
            break;
        }

        setLoading(false);
        return;
      }
    }

    // Update user information
    try {
      // Remove password from data object
      delete data.password;

      await editUserInfo({ ...data, profileComplete: true });

      // Update user state
      setUser({ ...user, ...data } as UserModel);

      // Reset form and set save status
      reset({ ...data });
      setIsSaved(true);
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError('Error al guardar información.');
    } finally {
      setLoading(false);
    }
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* Name Input Field */}
      <MDBValidationItem feedback={errors.name?.message || ' '} invalid={!!errors.name} className="mb-3">
        <MDBInput
          label="Nombre"
          {...register('name', nameValidation())}
          defaultValue={user?.name}
          type="text"
          ref={createRefHandler(nameRef, errors.name)}
          aria-describedby="nameDescription"
        />
        <div id="nameDescription" className={!!errors.name ? 'form-text mt-4' : 'form-text'}>
          Tu nombre y apellido
        </div>
      </MDBValidationItem>
      {/* Email Input Field (Editable) */}
      <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-3">
        <MDBInput
          label="Correo electrónico"
          {...register('email', emailValidation())}
          defaultValue={user?.email}
          type="email"
          ref={createRefHandler(emailRef, errors.email)}
          aria-describedby="emailDescription"
        />
        <div id="emailDescription" className={!!errors.email ? 'form-text mt-4' : 'form-text'}>
          Correo electrónico para ingresar al sistema
        </div>
      </MDBValidationItem>

      {/* Phone Input Field */}
      <MDBValidationItem feedback={errors.phone?.message || ' '} invalid={!!errors.phone} className="mb-3">
        <MDBInput
          label="Teléfono"
          {...register('phone', phoneValidation())}
          defaultValue={user?.phone}
          type="tel"
          ref={createRefHandler(phoneRef, errors.phone)}
          aria-describedby="phoneDescription"
        />
        <div id="phoneDescription" className={!!errors.phone ? 'form-text mt-4' : 'form-text'}>
          Número de teléfono de contacto
        </div>
      </MDBValidationItem>

      {/* Password Input Field */}
      <MDBValidationItem feedback={errors.password?.message || ' '} invalid={!!errors.password} className="mb-3">
        <MDBInput
          label="Contraseña"
          {...register('password', passwordValidation())}
          type="password"
          ref={createRefHandler(passwordRef, errors.password)}
          aria-describedby="passwordDescription"
        />
        <div id="passwordDescription" className={!!errors.password ? 'form-text mt-4' : 'form-text'}>
          Ingresa tu contraseña actual por seguridad
        </div>
      </MDBValidationItem>

      {/* Submit Button with Loading Indicator */}
      <MDBBtn type="submit" disabled={loading || !isDirty}>
        {loading ? (
          <>
            <MDBSpinner size="sm" role="status" className="me-2" />
            Guardando información...
          </>
        ) : isSaved ? (
          <>
            <MDBIcon fas icon="check" className="me-2" />
            Información guardada
          </>
        ) : (
          <>Guardar</>
        )}
      </MDBBtn>

      {/* Display General Error Message */}
      {generalError && <div className="text-danger mt-3">{generalError}</div>}
    </MDBValidation>
  );
};

export default React.memo(UserDetailsForm);
