import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MDBBtn, MDBIcon, MDBInput, MDBSpinner, MDBValidation, MDBValidationItem } from 'mdb-react-ui-kit';

import { auth } from '@/core/firebase';
import { useUser } from '@/core/hooks/useUser';
import createRefHandler from '@/core/utils/createRefHandler';
import useFormValidations from '@/core/hooks/useFormValidations';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';

/**
 * Represents the structure of the user's password for form submission.
 *
 * @typedef {Object} FormData
 * @property {string} currentPassword - The user's current password.
 * @property {string} password - The user's password.
 * @property {string} passwordConfirm - The user's password confirmation.
 */
type FormData = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
};

/**
 * Component for editting user profile, allowing users to edit their name, email and phone.
 * @returns {React.FC} The ProfileEditScreen component.
 */
const UserDetailsForm: React.FC = () => {
  // User hook for state management
  const { user } = useUser();

  // Form validation rules hook
  const { passwordValidation, passwordConfirmationValidation } = useFormValidations();

  // Setting up state management
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    getValues,
    setError,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { currentPassword: '', password: '', passwordConfirm: '' },
  });

  // Registering input fields with validation rules
  const currentPasswordRef = register('currentPassword', passwordValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;
  const passwordConfirmRef = register('passwordConfirm', passwordConfirmationValidation(getValues)).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    try {
      await reauthenticateWithCredential(
        auth.currentUser!,
        EmailAuthProvider.credential(user?.email!, data.currentPassword)
      );
    } catch (error: any) {
      console.error('Error during user password validation:', error);

      switch (error.code) {
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
          setError('currentPassword', { message: 'Contraseña incorrecta' });
          break;
        default:
          setGeneralError('Error al validar la contraseña');
          break;
      }

      setLoading(false);
      return;
    }

    try {
      await updatePassword(auth.currentUser!, data.password);
      reset();
      setIsSaved(true);
    } catch (error: any) {
      console.error('Error during user information update:', error);
      switch (error.code) {
        case 'auth/weak-password':
          setError('password', { message: 'La contraseña debe tener al menos 6 caracteres' });
          break;
        default:
          setGeneralError('Error al cambiar la contraseña');
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
      {/* Current Password Input Field */}
      <MDBValidationItem
        feedback={errors.currentPassword?.message || ' '}
        invalid={!!errors.currentPassword}
        className="mb-3"
      >
        <MDBInput
          label="Contraseña actual"
          {...register('currentPassword', passwordValidation())}
          type="password"
          ref={createRefHandler(currentPasswordRef, errors.currentPassword)}
          aria-describedby="currentPasswordDescription"
        />
        <div id="currentPasswordDescription" className={!!errors.currentPassword ? 'form-text mt-4' : 'form-text'}>
          Ingresa tu contraseña actual para confirmar los cambios
        </div>
      </MDBValidationItem>

      {/* Password Input Field */}
      <MDBValidationItem feedback={errors.password?.message || ' '} invalid={!!errors.password} className="mb-3">
        <MDBInput
          label="Nueva contraseña"
          {...register('password', passwordValidation())}
          type="password"
          ref={createRefHandler(passwordRef, errors.password)}
          aria-describedby="passwordDescription"
        />
        <div id="passwordDescription" className={!!errors.password ? 'form-text mt-4' : 'form-text'}>
          La contraseña debe tener al menos 6 caracteres
        </div>
      </MDBValidationItem>

      {/* Password Confirm Input Field */}
      <MDBValidationItem
        feedback={errors.passwordConfirm?.message || ' '}
        invalid={!!errors.passwordConfirm}
        className="mb-3"
      >
        <MDBInput
          label="Confirmar contraseña"
          {...register('passwordConfirm', passwordConfirmationValidation(getValues))}
          type="password"
          ref={createRefHandler(passwordConfirmRef, errors.passwordConfirm)}
          aria-describedby="passwordConfirmDescription"
        />
        <div id="passwordConfirmDescription" className={!!errors.passwordConfirm ? 'form-text mt-4' : 'form-text'}>
          Confirma tu nueva contraseña
        </div>
      </MDBValidationItem>

      {/* Submit Button with Loading Indicator */}
      <MDBBtn type="submit" disabled={loading || !isDirty}>
        {loading ? (
          <>
            <MDBSpinner size="sm" role="status" className="me-2" />
            Cambiando contraseña...
          </>
        ) : isSaved ? (
          <>
            <MDBIcon fas icon="check" className="me-2" />
            Contraseña cambiada
          </>
        ) : (
          <>Cambiar contraseña</>
        )}
      </MDBBtn>

      {/* Display General Error Message */}
      {generalError && <div className="text-danger mt-3">{generalError}</div>}
    </MDBValidation>
  );
};

export default React.memo(UserDetailsForm);
