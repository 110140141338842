import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Header from '@/features/common/components/Header';

/**
 * Props for the Container component.
 */
export interface ContainerProps {
  title: string;
  fluid?: boolean;
  navBarActions?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * Container component that provides a common layout structure.
 * It includes a header and a content area where children components are rendered.
 *
 * @param {ContainerProps} props - The props for the component.
 * @returns The Container component.
 */
const Container: React.FC<ContainerProps> = ({ title, fluid = false, navBarActions, children }) => {
  return (
    <main className="main h-100">
      <MDBContainer fluid className="h-100">
        <MDBRow className="justify-content-center h-100">
          <MDBCol md="12" className="p-0 h-100">
            <Header title={title} actions={navBarActions} />
            <MDBContainer
              fluid
              className={fluid ? 'px-0 h-100 bg-light' : 'px-4 pb-4 h-100 bg-light'}
              style={fluid ? { paddingTop: 'calc(58px)' } : { paddingTop: 'calc(58px + 1.5rem)' }}
            >
              {children}
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </main>
  );
};

export default React.memo(Container);
