import { FieldValues, UseFormGetValues } from 'react-hook-form';

const useFormValidations = () => {
  const emailValidation = (isRequired = true) => ({
    required: isRequired && 'Ingresa un correo electrónico',
    pattern: {
      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
      message: 'Ingresa un correo electrónico válido',
    },
  });

  const nameValidation = (isRequired = true) => ({
    required: isRequired && 'Ingresa un nombre y un apellido',
    pattern: {
      value: /^[a-zA-Z\s]+$/,
      message: 'Tienes que ingrear un nombre y un apellido',
    },
  });

  const phoneValidation = (isRequired = false) => ({
    required: isRequired && 'Ingresa un número de teléfono',
    pattern: {
      value: /^[2-7]\d{3}[- ]?\d{4}$/,
      message: 'Ingresa un número de teléfono válido',
    },
  });

  const passwordValidation = (isRequired = true) => ({
    required: isRequired && 'Ingresa una contraseña',
    minLength: {
      value: 6,
      message: 'Ingresa una contraseña de al menos 6 caracteres',
    },
  });

  const passwordConfirmationValidation = <T extends FieldValues>(getValues: UseFormGetValues<T>) => ({
    required: 'Ingresa la contraseña de nuevo',
    validate: (value: string | undefined) => value === getValues().password || 'Las contraseñas no coinciden',
  });

  const notEmptyValidation = (isRequired = true, message = 'Este campo es requerido') => ({
    required: isRequired && message,
  });

  const numberValidation = (isRequired = true) => ({
    required: isRequired && 'Ingresa un número',
    pattern: {
      value: /^\d+(\.\d+)?$/,
      message: 'Ingresa un número válido',
    },
  });

  return {
    emailValidation,
    nameValidation,
    phoneValidation,
    passwordValidation,
    passwordConfirmationValidation,
    notEmptyValidation,
    numberValidation,
  };
};

export default useFormValidations;
