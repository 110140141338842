import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';

import { formState } from '@/core/hooks/useForm';
import { useFormList } from '@/core/hooks/useFormList';
import { formTemplateListState } from '@/core/hooks/useFormTemplateList';
import { FormTemplateModel } from '@/features/templates/data/model/FormTemplateModel';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FormModel } from '../data/model/FormModel';

/**
 * Represents the props for the EditFormModal component.
 */
interface ViewFormModalProps {
  formId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * SendFormModal component for sending a form to a candidate
 *
 * @param {ViewFormModalProps} props - Component props
 * @returns {React.ReactElement} - The ViewFormModal component.
 */
const ViewFormModal: React.FC<ViewFormModalProps> = ({ formId, open, setOpen, toggle }: ViewFormModalProps): React.ReactElement => {
  // Form List hook for state management
  const { formList } = useFormList();

  // Form state for form data
  const [form, setForm] = useRecoilState<FormModel | null>(formState);
  const formData = useRecoilValue(formState);

  // Form Template List hook for state management
  const [formTemplateList, setFormTemplateList] = useRecoilState(formTemplateListState);

  // Form Template state for form template data
  const [formTemplate, setFormTemplate] = useState<FormTemplateModel>();

  // Setting up state management
  const [, setFetching] = useState<boolean>(true);

  const handleClose = () => {
    toggle();
  };

  // Fetch form template list
  const fetchFormTemplateList = httpsCallable(getFunctions(), 'getFormTemplates');

  /**
   * Fetches the list of admins from the database.
   */
  useEffect(() => {
    fetchFormTemplateList()
      .then((result) => {
        setFormTemplateList(result.data as FormTemplateModel[]);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formId && formTemplateList.length > 0) {
      const form = formList.find((form) => form.id === formId);
      if (form) {
        setForm(form);

        if (form.templateId) {
          const template = formTemplateList.find((template) => template.id === form.templateId);

          if (template) {
            setFormTemplate(template);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, formList, formTemplateList]);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen} staticBackdrop={true} className="modal-lg">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Formulario</MDBModalTitle>
            <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <div className="note note-light border-top border-bottom border-end pt-3 mb-3">
              <h5 className="mb-4">Información del candidato</h5>

              <h6 className="mb-0">Nombre completo</h6>
              <p>{form?.fullName}</p>

              <h6 className="mb-0">Correo electrónico</h6>
              <p>{form?.email}</p>

              <h6 className="mb-0">Teléfono</h6>
              <p className="mb-0">{form?.phone}</p>
            </div>

            {formTemplate?.sections.map((section) => (
              <div className="note note-light border-top border-bottom border-end pt-3 mb-3">
                <h5 className="mb-4">{section.name}</h5>
                {section.fields.map((field, index) => (
                  <>
                    <h6 className="mb-0">{field.label}</h6>
                    <p className={`${index === section.fields.length - 1 && 'mb-0'}`}>{formData?.formData[field.id]}</p>
                  </>
                ))}
              </div>
            ))}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn type="reset" onClick={handleClose}>
              Cerrar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(ViewFormModal);
