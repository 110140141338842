import { signOut, updatePassword } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { auth, functions } from '@/core/firebase';
import useFormValidations from '@/core/hooks/useFormValidations';
import { useUser } from '@/core/hooks/useUser';
import createRefHandler from '@/core/utils/createRefHandler';
import { UserModel } from '@/features/auth/data/models/UserModel';

/**
 * Represents the structure of the form data for editing a user's profile.
 *
 * @property {string} name - The user's name.
 * @property {string} phone - The user's phone number.
 * @property {string} password - The user's password.
 * @property {string} passwordConfirm - The user's password confirmation.
 */
type FormData = {
  name: string;
  phone: string;
  password?: string;
  passwordConfirm?: string;
};

/**
 * Component for user onboarding, allowing users to enter their name and phone.
 * @returns {React.ReactElement} The OnboardingScreen component.
 */
const OnboardingScreen: React.FC = (): React.ReactElement => {
  // Firebase User
  const [firebaseUser] = useAuthState(auth);

  // Navigation hook for redirecting users
  const navigate = useNavigate();

  // User hook for state management
  const { user } = useUser();

  // Form validation rules hook
  const { nameValidation, phoneValidation, passwordValidation, passwordConfirmationValidation } = useFormValidations();

  // State management
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { name: user?.name || '', phone: user?.phone || '', password: '', passwordConfirm: '' },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', nameValidation()).ref;
  const phoneRef = register('phone', phoneValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;
  const passwordConfirmRef = register('passwordConfirm', passwordConfirmationValidation(getValues)).ref;

  // Update form fields when user data changes
  useEffect(() => {
    setValue('name', user?.name || '');
    setValue('phone', user?.phone || '');
  }, [user, setValue]);

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    // Update password before updating user information
    try {
      await updatePassword(firebaseUser!, data.password!);
      delete data.password;
      delete data.passwordConfirm;
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError('Se ha agotado el tiempo de espera. Se cerrará la sesión. Por favor, inicia sesión nuevamente.');
      await new Promise((resolve) => setTimeout(resolve, 5000));
      signOut(auth);
    }

    // Firebase function for updating user information
    const setUser = httpsCallable(functions, 'setUser');
    const setUserProfileComplete = httpsCallable(functions, 'setUserProfileComplete');

    // Update user information
    try {
      await setUser({ ...user, ...data });
      await setUserProfileComplete();
      setUser({ ...user, ...data, profileComplete: true } as UserModel);
      reset({ ...data });
      setIsSaved(true);
      window.location.reload();
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError('Error al guardar información.');
    } finally {
      setLoading(false);
    }
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  useEffect(() => {
    if (user?.profileComplete) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <MDBContainer className="mt-5">
      <section className="text-center">
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size="12" md="8" lg="6" xl="5">
            <MDBCard>
              <MDBCardBody className="p-4">
                <h2 className="fw-bold mb-2 text-uppercase">Revisa tu información</h2>
                <p className="mb-5">Revisa que tu información sea correcta</p>
                <MDBValidation onSubmit={handleSubmit(onSubmit)}>
                  {/* Name Input Field */}
                  <MDBValidationItem
                    feedback={errors.name?.message || ' '}
                    invalid={errors.name ? true : false}
                    className="mb-3"
                  >
                    <MDBInput
                      label="Nombre"
                      {...register('name', nameValidation())}
                      defaultValue={user?.name}
                      type="text"
                      ref={createRefHandler(nameRef, errors.name)}
                      aria-describedby="nameDescription"
                    />
                    <div id="nameDescription" className={errors.name ? 'form-text mt-4' : 'form-text'}>
                      Tu nombre y apellido
                    </div>
                  </MDBValidationItem>

                  {/* Email Input Field (Read-only) */}
                  <MDBValidationItem className="mb-3">
                    <MDBInput
                      label="Correo electrónico"
                      defaultValue={user?.email || ''}
                      type="email"
                      disabled
                      aria-describedby="emailDescription"
                    />
                    <div id="emailDescription" className="form-text mt-4">
                      Correo electrónico para ingresar al sistema
                    </div>
                  </MDBValidationItem>

                  {/* Phone Input Field */}
                  <MDBValidationItem feedback={errors.phone?.message || ' '} invalid={!!errors.phone} className="mb-3">
                    <MDBInput
                      label="Teléfono"
                      {...register('phone', phoneValidation())}
                      defaultValue={user?.phone}
                      type="tel"
                      ref={createRefHandler(phoneRef, errors.phone)}
                      aria-describedby="phoneDescription"
                    />
                    <div id="phoneDescription" className={!!errors.phone ? 'form-text mt-4' : 'form-text'}>
                      Número de teléfono de contacto
                    </div>
                  </MDBValidationItem>

                  {/* Password Input Field */}
                  <MDBValidationItem
                    feedback={errors.password?.message || ' '}
                    invalid={!!errors.password}
                    className="mb-3"
                  >
                    <MDBInput
                      label="Contraseña"
                      {...register('password', passwordValidation())}
                      type="password"
                      ref={createRefHandler(passwordRef, errors.password)}
                      aria-describedby="passwordDescription"
                    />
                    <div id="passwordDescription" className={!!errors.password ? 'form-text mt-4' : 'form-text'}>
                      La contraseña debe tener al menos 6 caracteres
                    </div>
                  </MDBValidationItem>

                  {/* Password Confirm Input Field */}
                  <MDBValidationItem
                    feedback={errors.passwordConfirm?.message || ' '}
                    invalid={!!errors.passwordConfirm}
                    className="mb-3"
                  >
                    <MDBInput
                      label="Confirmar contraseña"
                      {...register('passwordConfirm', passwordConfirmationValidation(getValues))}
                      type="password"
                      ref={createRefHandler(passwordConfirmRef, errors.passwordConfirm)}
                      aria-describedby="passwordConfirmDescription"
                    />
                    <div
                      id="passwordConfirmDescription"
                      className={!!errors.passwordConfirm ? 'form-text mt-4' : 'form-text'}
                    >
                      Confirma tu nueva contraseña
                    </div>
                  </MDBValidationItem>

                  {/* Submit Button with Loading Indicator */}
                  <MDBBtn type="submit" size="lg" block disabled={loading || !isDirty}>
                    {loading ? (
                      <>
                        <MDBSpinner size="sm" role="status" className="me-2" />
                        Guardando información...
                      </>
                    ) : isSaved ? (
                      <>
                        <MDBIcon fas icon="check" className="me-2" />
                        Información guardada!
                      </>
                    ) : (
                      <>Continuar</>
                    )}
                  </MDBBtn>

                  {/* Display General Error Message */}
                  {generalError && <div className="text-danger mt-3">{generalError}</div>}
                </MDBValidation>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
};

export default React.memo(OnboardingScreen);
