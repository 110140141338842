import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { functions } from '@/core/firebase';
import { useAdminList } from '@/core/hooks/useAdminList';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { User } from '@/features/common/data/interface/User';

/**
 * Represents the props for the EditAdminModal component.
 *
 * @typedef {Object} EditAdminModalProps
 * @property {string} uid - The user's uid.
 * @property {boolean} open - Whether the modal is open or not.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpen - Function to set the open state.
 * @property {() => void} toggle - Function to toggle the modal.
 */
interface EditAdminModalProps {
  uid?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * Represents the structure of the form data for creating a new admin.
 *
 * @typedef {Object} FormData
 * @property {string} name - The user's name.
 * @property {string} email - The user's email.
 * @property {string} phone - The user's phone number.
 * @property {string} password - The user's password.
 * @property {string} passwordConfirm - The user's password confirmation.
 */
type FormData = {
  name: string;
  email: string;
  phone: string;
  password?: string;
  passwordConfirm?: string;
};

/**
 * EditAdminModal component for creating a new admin.
 *
 * @param {EditAdminModalProps} props - Component props
 * @returns {React.ReactElement} - The EditAdminModal component.
 */
const EditAdminModal: React.FC<EditAdminModalProps> = ({ uid, open, setOpen, toggle }) => {
  // Admin list hook for state management
  const { adminList, setAdminList } = useAdminList();

  // Form validation rules hook
  const { nameValidation, emailValidation, phoneValidation, passwordValidation, passwordConfirmationValidation } =
    useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string | null>(null);
  const [admin, setAdmin] = useState<User | null>(null);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: admin?.name || '',
      email: admin?.email || '',
      phone: admin?.phone || '',
      password: '',
      passwordConfirm: '',
    },
  });

  // Registering input fields with validation rules
  const nameRef = register('name', nameValidation()).ref;
  const emailRef = register('email', emailValidation()).ref;
  const phoneRef = register('phone', phoneValidation()).ref;
  const passwordRef = register('password', passwordValidation()).ref;
  const passwordConfirmRef = register('passwordConfirm', passwordConfirmationValidation(getValues)).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);

    try {
      // Update user information
      await httpsCallable(functions, 'setUser')({ uid: admin?.uid, ...data });

      // Remove password fields from data
      delete data.password;
      delete data.passwordConfirm;

      // Update admin list
      setAdminList(
        adminList.map((adminItem) => (adminItem.uid === admin?.uid ? { ...adminItem, ...data } : adminItem))
      );

      // Reset form and close modal
      reset({ ...data });
      toggle();
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setGeneralError('Error al actualizar la información del administrador');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAdmin(null);
    toggle();
    reset();
  };

  // Set admin when uid changes
  useEffect(() => {
    setAdmin(adminList.find((admin) => admin.uid === uid) || null);
  }, [adminList, uid]);

  // Update form fields when admin data changes
  useEffect(() => {
    if (admin !== null) {
      setValue('name', admin?.name || '');
      setValue('email', admin?.email || '');
      setValue('phone', admin?.phone || '');
      setValue('password', '');
      setValue('passwordConfirm', '');
    } else {
      reset();
    }
  }, [admin, setValue, reset]);

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBModalHeader>
              <MDBModalTitle>Editar Administrador</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <h6 className="fw-bold mb-3">Información de perfil</h6>

              {/* Name Input Field */}
              <MDBValidationItem feedback={errors.name?.message || ' '} invalid={!!errors.name} className="mb-3">
                <MDBInput
                  label="Nombre"
                  {...register('name', nameValidation())}
                  defaultValue={admin?.name}
                  type="text"
                  ref={createRefHandler(nameRef, errors.name)}
                  aria-describedby="nameDescription"
                />
                <div id="nameDescription" className={!!errors.name ? 'form-text mt-4' : 'form-text'}>
                  Nombre y apellido del administrador
                </div>
              </MDBValidationItem>
              {/* Email Input Field (Editable) */}
              <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email} className="mb-3">
                <MDBInput
                  label="Correo electrónico"
                  {...register('email', emailValidation())}
                  defaultValue={admin?.email}
                  type="email"
                  ref={createRefHandler(emailRef, errors.email)}
                  aria-describedby="emailDescription"
                />
                <div id="emailDescription" className={!!errors.email ? 'form-text mt-4' : 'form-text'}>
                  Correo electrónico para ingresar al sistema
                </div>
              </MDBValidationItem>

              {/* Phone Input Field */}
              <MDBValidationItem feedback={errors.phone?.message || ' '} invalid={!!errors.phone} className="mb-3">
                <MDBInput
                  label="Teléfono"
                  {...register('phone', phoneValidation())}
                  defaultValue={admin?.phone}
                  type="tel"
                  ref={createRefHandler(phoneRef, errors.phone)}
                  aria-describedby="phoneDescription"
                />
                <div id="phoneDescription" className={!!errors.phone ? 'form-text mt-4' : 'form-text'}>
                  Número de teléfono de contacto
                </div>
              </MDBValidationItem>

              <hr className="mb-3" />

              <h6 className="fw-bold mb-3">Cambiar contraseña</h6>

              {/* Password Input Field */}
              <MDBValidationItem
                feedback={errors.password?.message || ' '}
                invalid={!!errors.password}
                className="mb-3"
              >
                <MDBInput
                  label="Nueva contraseña"
                  {...register('password', passwordValidation())}
                  type="password"
                  ref={createRefHandler(passwordRef, errors.password)}
                  aria-describedby="passwordDescription"
                />
                <div id="passwordDescription" className={!!errors.password ? 'form-text mt-4' : 'form-text'}>
                  La nueva contraseña debe tener al menos 6 caracteres
                </div>
              </MDBValidationItem>

              {/* Password Confirm Input Field */}
              <MDBValidationItem
                feedback={errors.passwordConfirm?.message || ' '}
                invalid={!!errors.passwordConfirm}
                className="mb-3"
              >
                <MDBInput
                  label="Confirmar contraseña"
                  {...register('passwordConfirm', passwordConfirmationValidation(getValues))}
                  type="password"
                  ref={createRefHandler(passwordConfirmRef, errors.passwordConfirm)}
                  aria-describedby="passwordConfirmDescription"
                />
                <div
                  id="passwordConfirmDescription"
                  className={!!errors.passwordConfirm ? 'form-text mt-4' : 'form-text'}
                >
                  Confirma la nueva contraseña
                </div>
              </MDBValidationItem>

              {/* Display General Error Message */}
              {generalError && <div className="text-danger mt-3">{generalError}</div>}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="reset" color="light" onClick={handleClose}>
                Cerrar
              </MDBBtn>
              <MDBBtn type="submit" disabled={loading || !isDirty}>
                {loading ? (
                  <>
                    <MDBSpinner size="sm" role="status" className="me-2" />
                    Guardando...
                  </>
                ) : isSaved ? (
                  <>
                    <MDBIcon fas icon="check" className="me-2" />
                    Guardado
                  </>
                ) : (
                  <>Guardar</>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(EditAdminModal);
