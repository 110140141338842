import React from 'react';

/**
 * FormsScreen component represents the list of forms in the application.
 * @returns The FormsScreen component.
 */
const TermsScreen: React.FC = () => {
  return (
    <div className="container my-3">
      <div className="row mb-3">
        <div className="col-12 mx-auto">
          <h1>Términos de uso y procesamiento de la información</h1>
          <p>
            Las respuestas dadas anteriormente son verdaderas y completas según mi leal saber y entender. Si logro el
            puesto de trabajo, entiendo que la información falsa o engañosa proporcionada en mi aplicación o entrevista
            puede ser causa de despido. Autorizo voluntariamente para que la información recopilada y/o proporcionada
            por entidades públicas o privadas y la generada de relaciones contractuales, crediticias o comerciales, sea
            reportada a entidades que prestan servicios de información, centrales de riesgo o burós de crédito para ser
            tratada, almacenada o transferida; y autorizo expresamente a las entidades que prestan servicios de
            información, centrales de riesgos y burós de crédito, a recopilar, difundir o comercializar reportes o
            estudios que contengan información sobre mi persona.
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TermsScreen);
