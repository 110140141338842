import { atom, selector, useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { UserModel } from "@/features/auth/data/models/UserModel";

// Atom and Selector definitions (as provided)
const userState = atom<UserModel | null>({
  key: "user",
  default: {
    name: "",
    email: "",
    accessLevel: "candidate",
    phone: "",
    profileComplete: false,
    isActive: true,
  },
});

const userSelector = selector({
  key: "userState",
  get: ({ get }) => get(userState),
});

// The custom hook
export const useUser = () => {
  const user = useRecoilValue(userSelector); // To read the user state
  const setUser = useSetRecoilState(userState); // To update the user state
  const [userStateValue, setUserState] = useRecoilState(userState); // For both reading and updating

  return {
    user,
    setUser,
    userState: userStateValue,
    setUserState,
  };
};