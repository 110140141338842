import { atom, selector, useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { AdminModel } from "@/features/admins/data/models/AdminModel";

const adminListState = atom<AdminModel[]>({
  key: "adminListState",
  default: [],
});

const adminsSelector = selector({
  key: "adminsSelector",
  get: ({ get }) => get(adminListState),
});

export const useAdminList = () => {
  const adminList = useRecoilValue(adminsSelector); // For reading the admin list
  const setAdminList = useSetRecoilState(adminListState); // For updating the admin list
  const [adminListStateValue, setAdminListState] = useRecoilState(adminListState); // For both reading and updating

  return {
    adminList,
    setAdminList,
    adminListState: adminListStateValue,
    setAdminListState,
  };
};