import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Props for the ConditionalRoute component.
 * @property {boolean} condition - A boolean determining if the route is accessible.
 * @property {string} redirectTo - The path to redirect to if the condition is not met.
 */
interface ConditionalRouteProps {
  condition: boolean;
  redirectTo: string;
}

/**
 * A component that conditionally renders an Outlet or redirects to a specified path.
 * It is used to manage route access based on specific conditions (e.g., user authentication).
 *
 * @param {ConditionalRouteProps} props - The properties including the condition and the redirect path.
 * @returns {React.ReactElement} - Either an Outlet or a Navigate component based on the given condition.
 */
const ConditionalRoute: React.FC<ConditionalRouteProps> = ({ condition, redirectTo }) =>
  condition ? <Outlet /> : <Navigate to={redirectTo} />;

export default React.memo(ConditionalRoute);
