import { httpsCallable } from 'firebase/functions';
import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBSpinner,
  MDBValidation,
  MDBValidationItem,
} from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { functions } from '@/core/firebase';
import { useAdminList } from '@/core/hooks/useAdminList';
import useFormValidations from '@/core/hooks/useFormValidations';
import createRefHandler from '@/core/utils/createRefHandler';
import { User } from '@/features/common/data/interface/User';

/**
 * Represents the props for the CreateAdminModal component.
 *
 * @typedef {Object} CreateAdminModalProps
 * @property {boolean} open - Whether the modal is open or not.
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpen - Function to set the open state.
 * @property {() => void} toggle - Function to toggle the modal.
 */
interface CreateAdminModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
}

/**
 * Represents the structure of the form data for creating a new admin.
 *
 * @typedef {Object} FormData
 * @property {string} email - The user's email.
 */
type FormData = {
  email: string;
};

/**
 * CreateAdminModal component for creating a new admin.
 *
 * @param {CreateAdminModalProps} props - Component props
 * @returns {React.ReactElement} - The CreateAdminModal component.
 */
const CreateAdminModal: React.FC<CreateAdminModalProps> = ({ open, setOpen, toggle }) => {
  const { adminList, setAdminList } = useAdminList();
  const { emailValidation } = useFormValidations();

  // Setting up state management
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  // useForm hook initialization with form validation rules
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '' },
  });

  // Registering input fields with validation rules
  const emailRef = register('email', emailValidation()).ref;

  // Form submission handler
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      // Calls 'createUser' Cloud Function to create the admin
      const createUserResult = await httpsCallable(functions, 'createUser')({ email: data.email });
      setAdminList([...adminList, createUserResult.data as User]);
      reset();
      toggle();
    } catch (error: any) {
      console.error('Error during user information update:', error);
      setError('email', { type: 'custom', message: 'Error al crear administrador' });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    reset();
    toggle();
  };

  // Reset save status when form is dirty
  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  return (
    <MDBModal tabIndex="-1" open={open} setOpen={setOpen}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBValidation onSubmit={handleSubmit(onSubmit)} noValidate>
            <MDBModalHeader>
              <MDBModalTitle>Crear Administrador</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" type="reset" onClick={handleClose}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {/* Email Input Field (Editable) */}
              <MDBValidationItem feedback={errors.email?.message || ' '} invalid={!!errors.email}>
                <MDBInput
                  label="Correo electrónico"
                  {...register('email', emailValidation())}
                  type="email"
                  ref={createRefHandler(emailRef, errors.email)}
                  aria-describedby="textExample1"
                />
                <div id="textExample1" className={!!errors.email ? 'form-text pt-3' : 'form-text'}>
                  Se le enviará un correo electrónico con un enlace para ingresar.
                </div>
              </MDBValidationItem>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn type="reset" color="light" onClick={handleClose}>
                Cerrar
              </MDBBtn>
              <MDBBtn type="submit" disabled={loading || !isDirty}>
                {loading ? (
                  <>
                    <MDBSpinner size="sm" role="status" className="me-2" />
                    Creando...
                  </>
                ) : isSaved ? (
                  <>
                    <MDBIcon fas icon="check" className="me-2" />
                    Creado
                  </>
                ) : (
                  <>Crear</>
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBValidation>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default React.memo(CreateAdminModal);
