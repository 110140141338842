import React from 'react';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

import Container from '@/features/common/components/Container';
import UserDetailsForm from '../components/UserDetailsForm';
import UserPasswordForm from '../components/UserPasswordForm';

/**
 * Component for editting user profile, allowing users to edit their name, email and phone.
 * @returns {React.FC} The ProfileEditScreen component.
 */
const ProfileEditScreen: React.FC = () => {
  return (
    <Container title="Perfil">
      <MDBRow className="d-flex justify-content-start">
        <MDBCol size="12" md="8" lg="6" xl="5">
          <h5 className="fw-bold mb-4">Editar perfil</h5>
          <UserDetailsForm />
          <hr className='my-4' />
          <h5 className="fw-bold mb-4">Cambiar contraseña</h5>
          <UserPasswordForm />
        </MDBCol>
      </MDBRow>
    </Container>
  );
};

export default React.memo(ProfileEditScreen);
