import { HttpsCallableResult, getFunctions, httpsCallable } from 'firebase/functions';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import ConditionalRoute from '@/core/constants/router/ConditionalRoute';
import { auth } from '@/core/firebase';
import { useUser } from '@/core/hooks/useUser';
import { UserModel } from '@/features/auth/data/models/UserModel';

import AdminsScreen from '@/features/admins/screens/AdminsScreen';
import ForgotPasswordScreen from '@/features/auth/screens/ForgotPasswordScreen';
import SignInScreen from '@/features/auth/screens/SignInScreen';
import CandidatesScreen from '@/features/candidates/screens/CandidatesScreen';
import ErrorScreen from '@/features/common/screens/ErrorScreen';
import LoadingScreen from '@/features/common/screens/LoadingScreen';
import NotFoundScreen from '@/features/common/screens/NotFoundScreen';
import DashboardScreen from '@/features/dashboard/screens/DashboardScreen';
import FormsScreen from '@/features/form/screens/FormListScreen';
import FormScreen from '@/features/form/screens/FormScreen';
import OnboardingScreen from '@/features/onboarding/screens/OnboardingScreen';
import ProfileScreen from '@/features/profile/screens/ProfileScreen';
import FormTemplateListScreen from '@/features/templates/screens/FormTemplateListScreen';
import EmailLinkSignInScreen from './features/auth/screens/EmailLinkSignInScreen';

import '@/App.scss';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import TermsScreen from './features/terms/screen/TermsScreen';

/**
 * Main application component responsible for routing and authentication handling.
 * Manages user state and navigates to appropriate screens based on authentication status.
 *
 * @returns {React.ReactElement} The main application component.
 */
const App: React.FC = (): React.ReactElement => {
  const [userError, setUserError] = useState<Error | null>(null);
  const [authUser, authLoading, authError] = useAuthState(auth);
  const { user, setUser } = useUser();

  /**
   * Effect to fetch user model data.
   * Updates both Recoil state and local state upon fetching user data.
   */
  useEffect(() => {
    if (authUser) {
      const me = httpsCallable(getFunctions(), 'me');
      me()
        .then((result) => {
          setUser(result.data as UserModel);
        })
        .catch((e) => {
          setUserError(e);
          setUser(null);
          auth.signOut();
        });
    } else {
      setUser(null);
    }
  }, [authUser, setUser]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const id = window.location.pathname.split('/f/')[1];

    if (!authUser && id && token) {
      // Get the verify token https callable
      const verifyToken = httpsCallable(getFunctions(), 'verifyToken');

      // Verify the token
      verifyToken({ id, token })
        .then((result: HttpsCallableResult<any>) => {
          if (result.data && result.data && result.data.length > 0) {
            signInWithCustomToken(getAuth(), result.data).catch((error) => {
              setUserError(error);
              setUser(null);
            });
          } else {
            setUserError(new Error('Custom token not found in the response'));
            setUser(null);
          }
        })
        .catch((e) => {
          setUserError(e);
          setUser(null);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render error screen in case of authentication errors
  if (authError || userError) {
    return <ErrorScreen message={authError?.message || userError?.message || 'Unknown error'} />;
  }

  // Render loading screen during loading process or user model fetching
  if (authLoading || (authUser && !user)) {
    return <LoadingScreen />;
  }

  // Routing logic based on user and user model states
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            !!authUser && !!user && (user.accessLevel === 'super-admin' || user.accessLevel === 'admin') ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/auth/sign-in" />
            )
          }
        />
        {/* Redirect to sign in if user is not authenticated */}
        <Route
          element={
            <ConditionalRoute
              condition={!!authUser && !!user && (user.accessLevel === 'super-admin' || user.accessLevel === 'admin')}
              redirectTo="/auth/sign-in"
            />
          }
        >
          {/* Redirect to onboarding if user is not onboarded */}
          <Route element={<ConditionalRoute condition={!!user?.profileComplete} redirectTo="/onboarding" />}>
            {/* Redirect to dashboard if user is not a super admin */}
            <Route
              element={<ConditionalRoute condition={user?.accessLevel === 'super-admin'} redirectTo="/dashboard" />}
            >
              <Route path="/admins" element={<AdminsScreen />} />
            </Route>
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/dashboard" element={<DashboardScreen />} />
            <Route path="/candidates" element={<CandidatesScreen />} />
            <Route path="/forms" element={<FormsScreen />} />
            <Route path="/templates" element={<FormTemplateListScreen />} />
          </Route>
          {/* Redirect to dashboard if user is onboarded */}
          <Route element={<ConditionalRoute condition={!!!user?.profileComplete} redirectTo="/dashboard" />}>
            <Route element={<OnboardingScreen />} path="/onboarding" />
          </Route>
        </Route>
        {/* Redirect to dashboard if user is authenticated */}
        <Route
          element={
            <ConditionalRoute
              condition={!authUser || (user?.accessLevel === 'candidate')}
              redirectTo="/dashboard"
            />
          }
        >
          <Route path="/auth/sign-in" element={<SignInScreen />} />
          <Route path="/auth/sign-in/email-link" element={<EmailLinkSignInScreen />} />
          <Route path="/auth/forgot-password" element={<ForgotPasswordScreen />} />
        </Route>
        <Route path="/f/:id" element={<FormScreen />} />
        <Route path="/terminos" element={<TermsScreen />} />
        {/* Redirect to not found screen if no route matches */}
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </Router>
  );
};

export default React.memo(App);
